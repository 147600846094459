import React, { useMemo } from 'react';
import { useResources } from 'components/data/Resources/hooks/useResources';
import SnapchatWrapper from './snapchat-wrapper';
import { ISnapchatData } from '../types/snapchat-preview.type';

const SnapchatSinglePreview: React.FC<ISnapchatData> = ({ headline, brand_name, items = [] }) => {
    const { web_view_properties, asset, call_to_action, creativeType } = items?.[0] || {};
    const { resources, loading } = useResources<{ snapchat_cta: Record<string, string> }>(['snapchat_cta']);

    const ctaLabel = useMemo(() => {
        if (loading) return '';
        return resources?.snapchat_cta?.[call_to_action] || call_to_action || '';
    }, [resources, call_to_action, loading]);

    return (
        <SnapchatWrapper asset={asset} cta={ctaLabel} brandName={brand_name} url={web_view_properties?.url} headline={headline} creativeType={creativeType} />
    );
};

export default SnapchatSinglePreview;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import { useResources } from 'components/data/Resources/hooks/useResources';
import useProgressTimer from '../../meta/helpers/useProgressTimer';
import SnapchatWrapper from './snapchat-wrapper';
import { ISnapchatData } from '../types/snapchat-preview.type';

import '../styles/snapchat-story-preview.scss';

const SnapchatStoryPreview: React.FC<ISnapchatData> = ({ headline, brand_name, items = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { selectedFrame } = useAdSetupContext();
    const { progress, resetProgress } = useProgressTimer(currentIndex);
    const currentItem = items?.[currentIndex] || {};

    useEffect(() => {
        setCurrentIndex(selectedFrame);
    }, [selectedFrame]);

    const nextSlide = useCallback(() => {
        if (currentIndex < items.length - 1) {
            setCurrentIndex((prev) => prev + 1);
            resetProgress();
        }
    }, [currentIndex, items.length, resetProgress]);

    const prevSlide = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
            resetProgress();
        }
    }, [currentIndex, resetProgress]);

    const getProgressValue = useCallback(
        (index: number) => {
            if (index === currentIndex) return progress;
            if (index < currentIndex) return 100;
            return 0;
        },
        [currentIndex, progress]
    );

    const { resources, loading } = useResources<{ snapchat_cta: Record<string, string> }>(['snapchat_cta']);

    const ctaLabel = useMemo(() => {
        if (loading) return '';
        return resources?.snapchat_cta?.[currentItem.call_to_action] || currentItem.call_to_action || '';
    }, [loading, resources, currentItem.call_to_action]);

    return (
        <SnapchatWrapper
            asset={currentItem.asset}
            brandName={brand_name}
            url={currentItem.web_view_properties?.url}
            headline={headline}
            cta={ctaLabel}
            creativeType={currentItem.creativeType}>
            <div className="snapchat-story__progress-wrapper">
                {items.map((_, index) => (
                    <LinearProgress key={index} variant="determinate" value={getProgressValue(index)} className="snapchat-story__progress" />
                ))}
            </div>

            <Button className="snapchat-story__see-more">See 1 more</Button>

            {items.length > 1 && (
                <div className="snapchat-story__navigation">
                    {currentIndex > 0 && (
                        <div className="snapchat-story__arrow-left" onClick={prevSlide}>
                            <Icon fontSize="large">chevron_left</Icon>
                        </div>
                    )}
                    {currentIndex < items.length - 1 && (
                        <div className="snapchat-story__arrow-right" onClick={nextSlide}>
                            <Icon fontSize="large">chevron_right</Icon>
                        </div>
                    )}
                </div>
            )}
        </SnapchatWrapper>
    );
};

export default SnapchatStoryPreview;
